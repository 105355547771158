exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-howto-js": () => import("./../../../src/pages/howto.js" /* webpackChunkName: "component---src-pages-howto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-myaccount-js": () => import("./../../../src/pages/myaccount.js" /* webpackChunkName: "component---src-pages-myaccount-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-topup-js": () => import("./../../../src/pages/topup.js" /* webpackChunkName: "component---src-pages-topup-js" */),
  "component---src-pages-winners-js": () => import("./../../../src/pages/winners.js" /* webpackChunkName: "component---src-pages-winners-js" */),
  "component---src-pages-withdraw-js": () => import("./../../../src/pages/withdraw.js" /* webpackChunkName: "component---src-pages-withdraw-js" */)
}

